import React from 'react';

export const DCFlag = ({ style }) => (
  <svg viewBox="0 0 800 400" xmlns="http://www.w3.org/2000/svg" style={style}>
    <g>
      <rect x="0" y="0" fill="#000" fillRule="evenodd" strokeWidth="1pt" width="800" id="rect563" height="450"/>
      <rect x="-1" fill="#bf0000" fillRule="evenodd" strokeWidth="1pt" y="131.81889" width="800" id="rect551" height="90"/>
      <rect x="0" fill="#bf0000" fillRule="evenodd" strokeWidth="1pt" y="269.63779" width="800" id="rect552" height="90"/>
      <path stroke="null" fill="#bf0000" fillRule="evenodd" strokeWidth="1pt" id="path139" d="m280.1761,111.789l-30.76398,-23.048l-30.61383,23.275l11.38446,-37.8225l-30.55777,-23.356l37.80406,-0.325l11.72981,-37.709l11.97506,37.621l37.80406,0.049l-30.39861,23.576l11.63672,37.7395z"/>
      <path stroke="null" fill="#bf0000" fillRule="evenodd" strokeWidth="1pt" id="path564" d="m431.4581,111.789l-30.76398,-23.048l-30.61383,23.275l11.38446,-37.8225l-30.55777,-23.356l37.80406,-0.325l11.72981,-37.709l11.97506,37.621l37.80406,0.049l-30.39861,23.576l11.63672,37.7395z"/>
      <path stroke="null" fill="#bf0000" fillRule="evenodd" strokeWidth="1pt" id="path565" d="m581.73909,111.789l-30.76398,-23.048l-30.61383,23.275l11.38446,-37.8227l-30.55777,-23.356l37.80406,-0.325l11.72981,-37.709l11.97506,37.621l37.80406,0.049l-30.39861,23.576l11.63672,37.7397z"/>
    </g>
  </svg>
);

export const DistrictShowsFlag = ({ style }) => (
  <svg viewBox="0 0 800 400" style={style} xmlns="http://www.w3.org/2000/svg">
    <g display="inline">
      <rect height="450" id="rect563" width="800" strokeWidth="1pt" fillRule="evenodd" fill="black" stroke="null"/>
      <rect height="99.21259" id="rect551" width="800" y="131.81889" strokeWidth="1pt" fillRule="evenodd" fill="#bf0000" stroke="null" x="-1"/>
      <rect height="99.21259" id="rect552" width="800" y="269.63779" strokeWidth="1pt" fillRule="evenodd" fill="#bf0000" stroke="null" x="0"/>
      <path d="m280.1761,111.789l-30.76398,-23.048l-30.61383,23.275l11.38446,-37.8225l-30.55777,-23.356l37.80406,-0.325l11.72981,-37.709l11.97506,37.621l37.80406,0.049l-30.39861,23.576l11.63672,37.7395l0.00002,0z" id="path139" strokeWidth="1pt" fillRule="evenodd" fill="#bf0000" stroke="null"/>
      <path d="m431.4581,111.789l-30.76398,-23.048l-30.61383,23.275l11.38446,-37.8225l-30.55777,-23.356l37.80406,-0.325l11.72981,-37.709l11.97506,37.621l37.80406,0.049l-30.39861,23.576l11.63672,37.7395l0.00002,0z" id="path564" strokeWidth="1pt" fillRule="evenodd" fill="#bf0000" stroke="null"/>
      <path d="m581.73909,111.789l-30.76398,-23.048l-30.61383,23.275l11.38446,-37.8227l-30.55777,-23.356l37.80406,-0.325l11.72981,-37.709l11.97506,37.621l37.80406,0.049l-30.39861,23.576l11.63672,37.7397l0.00002,0z" id="path565" strokeWidth="1pt" fillRule="evenodd" fill="#bf0000" stroke="null"/>
      <text fontWeight="bold" stroke="null" transform="matrix(3.9678193268435393,0,0,3.9678193268435393,-228.1929721931689,-589.4448263901876) " textAnchor="middle" fontFamily="Sans-serif" fontSize="24" id="svg_1" y="202.70453" x="158" strokeWidth="0" fill="#000000">District</text>
      <text id="svg_2" fontWeight="bold" stroke="null" transform="matrix(3.9678193268435393,0,0,3.9678193268435393,-228.1929721931689,-589.4448263901876) " textAnchor="middle" fontFamily="Sans-serif" fontSize="24" y="236.76277" x="158" strokeWidth="0" fill="#000000">Shows</text>
    </g>
  </svg>
);
