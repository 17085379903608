import React from 'react';
import styled from 'styled-components';
import * as moment from 'moment';

import {
  COLORS,
  BOX_SHADOW,
  TEXT_SIZES,
} from './StyleVariables';

const ShowWrapper = styled.div`
  border-bottom: 0.5px ${COLORS.white} solid;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
  @media (min-width: 800px) {
    justify-content: normal;
  }
`;

const TextWrapper = styled.div`
  text-decoration: none;
  color: ${COLORS.white};
  cursor: ${ props => !!props.href ? 'pointer' : 'default' };
  margin-left: 25px;
  text-align: start;
  max-width: 400px;
  min-width: 300px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  width: 300px;
  padding: 10px;
`;

const Image = styled.img`
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;
`;

const DateWapper = styled.span`
  font-size: 14px;
`;

const Headline = styled.h2`
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.5em;
  margin-block-end: .75em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 800px) {
    width: 150px;
    margin-right: 0;
  }
`;

const SoldOut = styled.p`
  background-color: ${COLORS.red};
  font-weight: bold;
  padding: 8px;
  color: ${COLORS.white};
`;

const MoreInfo = styled.a`
  background-color: tansparent;
  font-weight: bold;
  padding: 8px;
  border: 1px ${COLORS.white} solid;
  color: ${COLORS.white};
  text-decoration: none;
  box-shadow: ${BOX_SHADOW.normal};
  cursor: pointer;
  &:hover {
    box-shadow: ${BOX_SHADOW.hover};
  }
`;

const Tickets = styled.a`
  background-color: ${COLORS.white};
  font-weight: bold;
  padding: 8px;
  color: ${COLORS.red};
  text-decoration: none;
  box-shadow: ${BOX_SHADOW.normal};
  cursor: pointer;
  &:hover {
    box-shadow: ${BOX_SHADOW.hover};
  }
`;

const Show = (props) => {
  
  let details = null;
  if (!!props.soldOut) {
    details = (
      <DetailsWrapper>
        <SoldOut>SOLD OUT</SoldOut>
        <MoreInfo target="_blank" href={props.detailLink}>
          { props.allVenues[props.venue].displayName }
        </MoreInfo>
      </DetailsWrapper>
    );
  } else {
    details = (
      <DetailsWrapper>
        <p>{ props.price }</p>
        { !!props.tixLink ? <Tickets target="_blank" href={props.tixLink}>Tickets</Tickets> : null }
        <MoreInfo target="_blank" href={props.detailLink}>
          { props.allVenues[props.venue].displayName }
        </MoreInfo>
      </DetailsWrapper>
    );
  }
  return (
    <ShowWrapper>
    
      <ImageWrapper>
        <Image src={props.image} />
      </ImageWrapper>
    
      <TextWrapper>
        <DateWapper>{ moment.unix(props.date).format('MMM Do YYYY') } | { props.time }</DateWapper>
        <Headline>{ props.headline }</Headline>
        <p>{ props.support }</p>
      </TextWrapper>
      
      { details }
    
    </ShowWrapper>
  );
};

export default Show;
