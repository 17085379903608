import * as firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyDN-U5mLCvMG99Nd6c0z07r66X4zV3v4ME",
  authDomain: "district-shows.firebaseapp.com",
  databaseURL: "https://district-shows.firebaseio.com",
  projectId: "district-shows",
  storageBucket: "district-shows.appspot.com",
  messagingSenderId: "65453958598"
};

firebase.initializeApp(config);


export const firestore = firebase.firestore();
