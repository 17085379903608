import React, { Component } from 'react';
import styled from 'styled-components';

import {
  COLORS,
  BOX_SHADOW,
  TEXT_SIZES,
} from './StyleVariables';
import { TIME_FRAMES } from './utils';

const AllFilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px ${COLORS.red} solid;
  padding-top: ${props => props.open ? '15px' : '0' };
  padding-bottom: ${props => props.open ? '15px' : '0' };
  height: ${props => props.open ? 'inherit' : '0' };
  overflow: ${props => props.open ? 'inherit' : 'hidden' };
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Filter = styled.div`
  background-color: ${ props => props.active ? COLORS.red : 'transparent'};
  border-color: ${ props => props.active ? COLORS.red : COLORS.white};
  color: ${COLORS.white};
  border-style: solid;
  border-width: 1px;
  padding: 5px 10px;
  margin: 8px;
  border-radius: 25px;
  box-shadow: ${BOX_SHADOW.normal};
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &:hover {
    box-shadow: ${BOX_SHADOW.hover};
  }
`;

const ShowFilterButton = styled.button`
  background-color: ${COLORS.red};
  border: 1px ${COLORS.red} solid;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  color: ${COLORS.white};
`;

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersOpen: true
    };
    this.triggerFilterWrapper = this.triggerFilterWrapper.bind(this);
  }

  triggerFilterWrapper() {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    })
  }

  renderFilters() {
    const { triggerVenueFilter, venues, activeVenues } = this.props;

    return Object.keys(venues).map((v, i) => {
      return (
        <Filter key={v} onClick={() => triggerVenueFilter(v)} active={(activeVenues.indexOf(v) >= 0)}>
          <span>{ venues[v].displayName }</span>
        </Filter>
      );
    });
  }

  renderDateFilters() {
    const { triggerDateFilter, activeDate } = this.props;

    return Object.keys(TIME_FRAMES).map((v, i) => {
      return (
        <Filter key={v} onClick={() => triggerDateFilter(v)} active={activeDate === v}>
          <span>{ TIME_FRAMES[v].displayName }</span>
        </Filter>
      );
    });
  }

  render () {
    return (
      <div>
        <AllFilterWrapper open={this.state.filtersOpen}>
          <FilterWrapper>  
            { this.renderFilters() }
          </FilterWrapper>
          <FilterWrapper>  
            { this.renderDateFilters() }
          </FilterWrapper>
        </AllFilterWrapper>
        <ShowFilterButton onClick={this.triggerFilterWrapper} >
          FILTERS
        </ShowFilterButton>
      </div>
    );
  }
}

export default Filters;
