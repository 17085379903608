import React from 'react';
import styled from 'styled-components';
import {
  COLORS,
  BOX_SHADOW,
  TEXT_SIZES,
} from './StyleVariables';

const BlackOutBlocker = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-items: center;
  background: black;
  color: white;
  z-index: 99;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
`;

const StyledH1 = styled.h1`
  font-size: 80px;
  font-weight: 800;
`;

const DonateLink = styled.a`
  background-color: tansparent;
  font-weight: bold;
  padding: 8px;
  border: 1px ${COLORS.white} solid;
  color: ${COLORS.white};
  text-decoration: none;
  box-shadow: ${BOX_SHADOW.normal};
  cursor: pointer;
  &:hover {
    box-shadow: ${BOX_SHADOW.hover};
  }
`;

const ContinueButton = styled.button`
  background-color: ${COLORS.red};
  font-weight: bold;
  padding: 8px;
  border: 1px ${COLORS.black} solid;
  color: ${COLORS.black};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    box-shadow: ${BOX_SHADOW.hover};
  }
`;

export const BlackOut = ({continueToDS}) => {
  return (
    <BlackOutBlocker>
      <StyledH1>BLACK LIVES MATTER</StyledH1>
      <DonateLink target="_blank" href="https://secure.actblue.com/donate/ms_blm_homepage_2019">
        Donate to Black Lives Matter
      </DonateLink>
      <DonateLink target="_blank" href="https://org2.salsalabs.com/o/6857/p/salsa/donation/common/public/?donate_page_KEY=15780&_ga=2.167159483.462004490.1590944290-1876661638.1590944290">
        Donate to NAACP Legal Defence and Educational Fund
      </DonateLink>
      <ContinueButton onClick={continueToDS}>
        Upcoming Shows
      </ContinueButton>
    </BlackOutBlocker>
  );
}