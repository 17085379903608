import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import AllShows from '../containers/AllShows';
import { BlackOut } from '../components/BlackOut'

const AppWrapper = styled.div`
  text-align: center;
  color: #fff;
  padding: 0;
`;

const ContentWapper = styled.div`
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
`;

const App = () => {
  const [ showBlackout, changeBackout ] = useState(true);
  const continueToDS = useCallback(() => changeBackout(!showBlackout),
    [showBlackout, changeBackout]);

  return (
    <AppWrapper>
     { showBlackout ? ( <BlackOut continueToDS={continueToDS} /> ) :
        (<ContentWapper>
          <Header />
          <AllShows />
        </ContentWapper>)
      }
    </AppWrapper>
  );
}

export default App;
