import React, { Component } from 'react';
import styled from 'styled-components';
import { firestore } from '../../core/firebase';

import Show from '../../components/Show';
import Filters from '../../components/Filters';
import { TIME_FRAMES } from '../../components/utils';

const AllShowsWrapper = styled.div`

`;

class AllShows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venues: [],
      showData: [],
      activeVenues: [],
      activeDate: 'all'
    };
    this.triggerVenueFilter = this.triggerVenueFilter.bind(this);
    this.triggerDateFilter = this.triggerDateFilter.bind(this);
  }

  componentDidMount() {
    this.getVenueData(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (Object.keys(prevState.venues).length !== Object.keys(this.state.venues).length) {
      this.getShowData(this.state.venues);
    }
  }

  getVenueData(setActive=false) {
    const venuesRef = firestore.collection('venues').doc('active');
    venuesRef.get().then((doc) => {
      if (doc.exists) {
        const venues = doc.data();
        if (setActive) {
          this.setState({ 
            venues,
            activeVenues: Object.keys(venues).map(v => venues[v].key )
          });
        } else {
          this.setState({ venues });
        }
      } else {
        console.error('There was a problem accessing all venues');
      }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  }

  getShowData(venues) {
    Object.keys(venues).forEach((venue) => {
      const docRef = firestore.collection('shows').doc(venues[venue].key);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const shows = doc.data().shows;
          this.setState({
            showData: [ ...this.state.showData, ...shows ]
          });
        } else {
          console.error(`There was a problem accessing: ${venues[venue].key}`);
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    });
  }

  momentObject() {
  }

  triggerVenueFilter(venue) {
    const idx = this.state.activeVenues.indexOf(venue);
    if (idx >= 0) {
      this.state.activeVenues.splice(idx, 1)
      this.setState({
        activeVenues: [
          ...this.state.activeVenues
        ]
      });
    } else {
      this.setState({
        activeVenues: [
          ...this.state.activeVenues,
          venue
        ]
      });
    }
  }

  triggerDateFilter(dateString) {
    this.setState({
      activeDate: dateString
    });
  }

  renderShows() {
    // moment().endOf()
    // const dateFilter
    return this.state.showData
      .filter((x) => x.date <= TIME_FRAMES[this.state.activeDate].moment)
      .filter((x) => this.state.activeVenues.indexOf(x.venue) >= 0)
      .sort((a,b) => a.date - b.date)
      .map((s, i) => <Show
        key={`${s.headline}_${s.venue}_${s.date}_${i}`} 
        allVenues={this.state.venues}
        {...s} 
      />)
  }

  render() {
    return (
      <AllShowsWrapper>
        <Filters 
          triggerVenueFilter={this.triggerVenueFilter}
          triggerDateFilter={this.triggerDateFilter}
          venues={this.state.venues}
          activeVenues={this.state.activeVenues}
          activeDate={this.state.activeDate}
        />
        { this.renderShows() }
      </AllShowsWrapper>
    );
  }
}

export default AllShows;
