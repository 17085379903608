import React from 'react';
import styled from 'styled-components';
import { DistrictShowsFlag } from './Icons';

const StyledHeader = styled.header`
  
`;

const Header = () => (

  <StyledHeader>
    <DistrictShowsFlag style={{ maxHeight: '400px' }}  />
  </StyledHeader>

);

export default Header;
