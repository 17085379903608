export const COLORS = {
  black: '#000',
  white: '#fff',
  red: '#bf0000',
  gray: '#727272',
  lightGray: '#c4c4c4',
  shadowGray: '#888888',
  darkGray: '#3e3e3e'
};

export const SCREEN_SIZES = {

};

export const BOX_SHADOW = {
  hover: `0px 0px 1px 3px ${COLORS.shadowGray}`,
  normal: `0px 0px 1px 1px ${COLORS.lightGray}`,
};

export const TEXT_SIZES = {
  normal: '14px',
  large: '16px',
  xlarge: '18px',
};
