import * as moment from 'moment';

export const TIME_FRAMES = {
  today: {
    displayName: 'Today',
    moment: moment().endOf('day').unix()
  },
  week: {
    displayName: 'Week',
    moment: moment().endOf('day').add(1, 'weeks').unix()
  },
  twoWeeks: {
    displayName: 'Two Weeks',
    moment: moment().endOf('day').add(2, 'weeks').unix()
  },
  month: {
    displayName: 'Month',
    moment: moment().endOf('day').add(1, 'months').unix()
  },
  twoMonths: {
    displayName: 'Two Months',
    moment: moment().endOf('day').add(2, 'months').unix()
  },
  all: {
    displayName: 'All',
    moment: moment().endOf('day').add(2, 'years').unix()
  },
};